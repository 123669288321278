//React
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import { minorScale, Pane, Card, SideSheet, Button, UnorderedList } from 'evergreen-ui';

//Files
import firebase from '../../../store/firebase';
import { actions } from '../../../store/actions';
import { messageTypes } from '../../../utils/constants';


const ProfileMenu = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isShown, onClose, isGuest, ...otherProps } = props;
  const closeSidebar = () => onClose();

  const doSignOut = () => {
    dispatch(actions.resetFirstLoad());
    dispatch(actions.profile.profileClear());
    dispatch(actions.accounts.accountsClear());
    dispatch(actions.accountUsers.accountUsersClear());
    dispatch(actions.accounts.accessibleAccountsClear());
    dispatch(actions.accounts.currentAccountClear());
    dispatch(actions.suppliers.suppliersClear());
    firebase.auth.signOut()
        .then(() => {
          dispatch(actions.appMessageSet('You have been signed out', messageTypes.SUCCESS));
          navigate('/signin');
        })
        .catch((err) => {
          console.log('User could not be signed out', err)
          dispatch(actions.appMessageSet('Failed to sign out', messageTypes.SUCCESS));
        });
    closeSidebar();
  };

  const doLeaveGroup = () => {
    dispatch(actions.resetFirstLoad());
    dispatch(actions.reports.clearReportData());
    dispatch(actions.profile.profileSetAccount(null));
    dispatch(actions.accounts.accountClear());
    dispatch(actions.accountUsers.accountUsersClear());
    dispatch(actions.accounts.accessibleAccountsClear());
    dispatch(actions.workingAccountClear());
    closeSidebar();
    navigate('/');

    dispatch(actions.appMessageSet('You have left your account', messageTypes.SUCCESS));
  };


  return (
    <SideSheet
        containerProps={{ display: 'flex', flexDirection: 'column' }}
        isShown={isShown}
        onCloseComplete={onClose}
        width={260}
        {...otherProps}
    >
      <Pane background="tint2" padding={minorScale(4)} flex="1 0 auto">
        <Card background="white" border="default" padding={minorScale(4)}>
          <UnorderedList listStyle="none" margin={0} padding={0}>
            <li>
              <Button
                  is={Link}
                  to="/profile"
                  appearance="minimal"
                  width="100%"
                  onClick={closeSidebar}
              >
                Profile
              </Button>
            </li>
            {!isGuest && (
            <li>
              <Button
                  appearance="minimal"
                  width="100%"
                  onClick={doLeaveGroup}
              >
                Leave Group
              </Button>
            </li>
            )}
            <li>
              <Button
                  appearance="minimal"
                  width="100%"
                  onClick={doSignOut}
              >
                Sign Out
              </Button>
            </li>
          </UnorderedList>
        </Card>
      </Pane>
    </SideSheet>
  )
};

ProfileMenu.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ProfileMenu;
